import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Fragment } from 'react'
import { Join13, Join14, Join15, Join16, Join17 } from '../components/icons'
import Modal from '../components/modal'
import { Layout } from '../components/layout'
import MoreInfo from '../components/recruitment/more-info'
import SectionFrameWrapper from '../components/recruitment/section-frame'
import Seo from '../components/seo'
// import Arrow from '../images/recruitment/arrow.png'
import BgImg from '../images/recruitment/banner_bg.png'
import BgImg2 from '../images/recruitment/bg.png'
import PlayImage from '../images/recruitment/play.png'
import TitleTextImage from '../images/recruitment/recruitment-student-title-text.png'
import TitleImage from '../images/recruitment/recruitment-student-title.png'
import Title2Image from '../images/recruitment/title2.png'
import subTitleImg from '../images/recruitment/title_bg.png'
import introductionVideo from '../assets/video/introduce.mp4'
import {
  Answer,
  // ArrowImgBox,
  // AttentionContainer,
  // AttentionContent,
  // AttentionTitle,
  BackgroundImg,
  // City,
  // CityAttention,
  // CityLine,
  ContentWrapper,
  // Date,
  Divider,
  FlowCircle,
  FlowItem,
  FlowItemBox,
  FlowItemContent,
  FlowRectangle,
  NoticeContent,
  PageStyle,
  ProcessContent,
  Question,
  SubmitBtn,
  SubTitle,
  Title,
  TitleBtnGroup,
  TitleButton,
  TitleImageContainer,
  VideoSource,
  Video,
  Link,
} from '../style/pages/campus-recruitment.atom'

const FlowList = [
  {
    key: 'flow1',
    pic: <Join13 />,
    title: '投递简历',
    content:
      '自主投递：可前往查看“校园招聘-职位列表”选择意向岗位进行投递\n' +
      '内推投递：向在瑞云在职的师哥师姐索要内推码或内推分享海报，通过链接上传简历填写个人信息',
    width: '90px',
    boxWidth: '113px',
    iconWidth: '42px',
    iconMini: '21px',
  },
  {
    key: 'flow2',
    pic: <Join14 />,
    title: '线上笔试/测评',
    content: '技术类岗需要参加线上笔试，职能、业务类岗需要参加线上测评。',
    width: '120px',
    boxWidth: '140px',
    iconWidth: '38px',
    iconMini: '19px',
  },
  {
    key: 'flow3',
    pic: <Join15 />,
    title: '安排面试',
    content:
      '所有岗位均采用线上面试，请各位同学提前准备相关设备（详情参见Q&A），并留意短信/邮件通知',
    width: '100px',
    boxWidth: '114px',
    iconWidth: '40px',
    iconMini: '20px',
  },
  {
    key: 'flow4',
    pic: <Join16 />,
    title: '发放OFFER',
    content: '面试通过后根据招聘进程陆续发放OFFER及签订三方协议',
    width: '110px',
    boxWidth: '127px',
    iconWidth: '42px',
    iconMini: '21px',
  },
  {
    key: 'flow5',
    pic: <Join17 />,
    title: '正式入职',
    content: '根据OFFER上标注的时间正式入职瑞云科技',
    width: '100px',
    boxWidth: '113px',
    iconWidth: '45px',
    iconMini: '22px',
  },
]

const KnowList = [
  {
    key: 'q1',
    q: '1. 本次校招面向的招聘对象是谁？',
    a:
      '2023届毕业生(毕业时间在2022年9月1日-2023年8月31日，中国大陆(内地)以毕业证为准，中国港澳台及海外地区以学位证为准)',
  },
  {
    key: 'q2',
    q: '2. 本次校招可以内推吗？内推有哪些优势？',
    a: '当然可以，如果你认识瑞云的小伙伴别忘了请他助你一臂之力。',
  },
  {
    key: 'q3',
    q: '3. 本次校招可以同时申请几个岗位？投递简历后，还可以修改吗？',
    a:
      '每人只可投递一个岗位，对于工作地点没有特殊要求的同学，建议"工作城市是否服从分配”选择“是”。原则上不允许修改岗位，如有特殊情况， 我们会公布相应的开放时间，请关注邮件、短信以及官网公告。',
  },
  {
    key: 'q4',
    q: '4. 本次校招对所学专业及学历有限制吗？不是相关专业的可以投递吗？',
    a:
      '本次校招主要面向软件工程/计算机科学与技术/电子信息工程等等专业优先，非相关专业的优秀同学也是可以投递的哦！！！',
  },
  {
    key: 'q5',
    q: '5. 本次校园招聘是线上面试还是线下面试？线上远程视频面试需要注意什么？',
    a:
      '线上加线下的模式，线下会在宣讲会现场面试。线上远程视频面试时，希望同学处于你熟悉且安静的环境中，需要: \n' +
      '1)摄像头:视频面试须全程开启摄像头功能，请提前准备好带有摄像功能的电脑参加面试; \n' +
      '2)软件:视频面试间需要使用腾讯会议或Chrome/QQ浏览器打开,请务必提前安装最新版腾讯会议或Chrome/QQ浏览器; \n' +
      '3)耳机:为保证交流过程音质清晰不受干扰，建议提前准备好耳机用于面试; \n' +
      '4)电脑和网络要求:面试中请关闭其他应用，尽量选择网络状况良好的环境进行，网络速度达到100kb/s以上;\n' +
      '建议同学们在面试前检查摄像头以及麦克风是否运转正常，网速是否达标且稳定。建议将手机热点作为备选方案，如遇到网速不佳的情况，及时切换。',
  },
  {
    key: 'q6',
    q: '6. 如果本次校招简历/笔试/面试未能通过筛选，会影响下次校招吗？',
    a: '不会的，本次校招没有通过的同学可以参加下一次的校招。',
  },
  {
    key: 'q7',
    q: '7. 网申岗位时，需要注意什么？',
    a:
      '需要保证简历真实性的情况下填写，并详细地填写您的实习和项目经历，更有可能获得HR的关注哦。另外，为避免在后续环节出现邮件接收延迟或被屏蔽的情况，我们推荐使用Outlook或163邮箱。',
  },
  {
    key: 'q8',
    q: '8. 投递简历后，多久会收到反馈？',
    a:
      '我们将于10月初陆续发出面试通知。通过初选及复试的同学（部分职位有笔试环节），将会收到面试邀请。在此过程中，请同学们保持通讯畅通，并留意短信和邮件通知哦~~',
  },
  {
    key: 'q9',
    q: '9. 所有职位都需要笔试吗？线上笔试需要注意什么？',
    a:
      '技术类岗需要参加线上笔试，职能、业务类岗需要参加线上测评。我们将于9月陆续通过邮件、短信等形式通知同学们参加笔试/测评，请同学们确保简历上留的邮箱及电话准确有效，并在投递后紧密留意邮箱来信或短信通知。笔试邮件有可能会被归入垃圾箱，请注意查看啦~~',
  },
  {
    key: 'q10',
    q: '10. 如果错过了笔试/面试，可以再安排一次吗？',
    a:
      '因为每年有大量同学参与校招，所以面试官往往日程紧张，面试时间难以调整。因此，我们建议各位同学尽量在约定的时间参加笔试/面试。如果确实有特殊原因无法参加，请联系相关HR，我们将尽力协调。',
  },
  {
    key: 'q11',
    q: '11. 面试完之后，多久可以得到最终答复？',
    a:
      '我们的面试结果一般会在面试后的3-5个工作日内通过邮件反馈给大家，大家注意及时关注邮件。即便没有通过也无需气馁，后续如有合适机会，HR伙伴会继续联系你哒。 ',
  },
  {
    key: 'q12',
    q: '12. 我可以通过哪些渠道了解瑞云相关招聘信息？ ',
    a:
      '除了官网之外，简历投递的渠道有以下三种，任选其一都能参加瑞云科技校园招聘哦，注意无须重复投递~~\n' +
      '1）招聘网站：前程无忧、智联招聘、拉勾、Boss直聘、猎聘等平台；\n' +
      '2）公司官网：“加入瑞云”链接到公司校招网站，在校招网站上传个人简历；\n' +
      '3）邮件投递：投递简历至：hr@rayvision.com。简历标题格式为：应聘岗位-学校-专业-姓名；',
  },
  {
    key: 'q13',
    q: '13. 本次校招的应聘流程是？ ',
    a: '投递简历-线上笔试/测评-安排面试（专业及HR面试）-发放OFFER- 正式入职',
  },
  {
    key: 'q14',
    q: '14. 以上问题都没有解答你的疑惑？ ',
    a:
      '请写明问题发送邮件至 hr@rayvision.com，会有HR小姐姐在三个工作日内回复~了解更多校园动态和安排请关注“瑞云科技招聘服务号”～等你！ ',
  },
]

const CampusRecruitmentPage = () => {
  const [visible, setVisible] = React.useState(false)
  return (
    <Layout id='lay'>
      <PageStyle />
      <Seo
        title='校园招聘 - 瑞云科技招聘'
        description='瑞云科技校园招聘-深圳市瑞云科技股份有限公司是一家专注为视觉行业提供垂直云计算服务的公司,随着5G的普及,瑞云科技作为“新型基础设施”,致力为视觉行业提供一系列IaaS、PaaS及SaaS云服务.加入我们,渲染无限未来！'
        keywords='瑞云校园招聘,瑞云网络校招,Rayvision 招聘'
      />
      <BackgroundImg src={BgImg} className='bg-img-web' alt='' />
      <BackgroundImg src={BgImg2} className='bg-img-mobile' alt='' />
      <Title>
        <TitleImageContainer>
          <img src={TitleTextImage} className='join-us' alt='join us' />
          <img src={TitleImage} className='title-image-web' alt='渲青春 染未来' />
          <img src={Title2Image} className='title-image-mobile' alt='渲青春 染未来' />
        </TitleImageContainer>
      </Title>
      <TitleBtnGroup>
        <TitleButton onClick={() => setVisible(true)}>
          <img src={PlayImage} alt='宣讲概览' className='play-image' />
          宣讲概览
        </TitleButton>
        <TitleButton
          className='submit-resume-btn'
          target='_blank'
          href='https://app.mokahr.com/campus-recruitment/rayvision/44818'
        >
          投递简历
        </TitleButton>
      </TitleBtnGroup>
      <ContentWrapper>
        {/* <ArrowImgBox>
          <img src={Arrow} alt='arrow' />
        </ArrowImgBox>
        <SectionFrameWrapper>
          <AttentionContent>
            <AttentionTitle>行程通知</AttentionTitle>
            <AttentionContainer>
              <CityAttention>
                <City>上海宣讲会</City>
                <Date>9月13日</Date>
              </CityAttention>
              <CityLine />
              <CityAttention>
                <City>长沙宣讲会</City>
                <Date>9月20日</Date>
              </CityAttention>
            </AttentionContainer>
            <CityLine className='center-line' />
            <AttentionContainer>
              <CityAttention>
                <City>广州宣讲会</City>
                <Date>9月27日</Date>
              </CityAttention>
              <CityLine />
              <CityAttention>
                <City>深圳宣讲会</City>
                <Date>10月10日</Date>
              </CityAttention>
            </AttentionContainer>
          </AttentionContent>
        </SectionFrameWrapper> */}
        <SubTitle>
          <img src={subTitleImg} alt='title' />
          <span> 招聘流程</span>
        </SubTitle>
        <SectionFrameWrapper>
          <ProcessContent>
            {FlowList.map((item) => (
              <FlowItem key={item.key}>
                <FlowItemBox boxWidth={item.boxWidth}>
                  <FlowCircle iconWidth={item.iconWidth} iconMini={item.iconMini}>
                    {item.pic}
                  </FlowCircle>
                  <FlowRectangle rectangleWidth={item.width}>{item.title}</FlowRectangle>
                  <StaticImage
                    className='arrow-image-web'
                    alt=''
                    src='../images/recruitment/arrow_x.png'
                  />
                  <StaticImage
                    className='arrow-image-mobile'
                    alt=''
                    src='../images/recruitment/arrow_x.png'
                    width={25}
                  />
                </FlowItemBox>
                {item.key !== 'flow1' ? (
                  <FlowItemContent>{item.content}</FlowItemContent>
                ) : (
                  <FlowItemContent>
                    <div>
                      自主投递：可前往查看“
                      <Link
                        style={{ color: '#02A6BC' }}
                        href='https://app.mokahr.com/campus-recruitment/rayvision/44818'
                        target='_blank'
                      >
                        校园招聘-职位列表
                      </Link>
                      ”选择意向岗位进行投递
                    </div>
                    <div>
                      内推投递：向在瑞云在职的师哥师姐索要内推码或内推分享海报，通过链接上传简历填写个人信息
                    </div>
                  </FlowItemContent>
                )}
              </FlowItem>
            ))}
          </ProcessContent>
        </SectionFrameWrapper>
        <SubTitle>
          <img src={subTitleImg} alt='title' />
          <span> 招聘须知</span>
        </SubTitle>
        <SectionFrameWrapper>
          <NoticeContent>
            {KnowList.map((know) => (
              <Fragment key={know.key}>
                <Question>{know.q}</Question>
                <Answer>{know.a}</Answer>
                {know.key === 'q14' && <MoreInfo />}
                <Divider />
              </Fragment>
            ))}
            <SubmitBtn
              href='https://app.mokahr.com/campus-recruitment/rayvision/44818'
              target='_blank'
            >
              投递简历
            </SubmitBtn>
          </NoticeContent>
        </SectionFrameWrapper>
      </ContentWrapper>
      <Modal show={visible} onClose={() => setVisible(false)}>
        <Video controls>
          <VideoSource src={introductionVideo} />
        </Video>
      </Modal>
      )
    </Layout>
  )
}

export default CampusRecruitmentPage
