import React from 'react'
import styled from 'styled-components'
import { color, Media } from '../../style/theme'
import PropTypes from 'prop-types'

const SectionFrame = styled.div.attrs((props) => ({
  cubeWidth: props.cubeWidth,
  cubeColor: props.cubeColor,
  borderWidth: props.borderWidth,
}))`
  position: relative;
  margin: 0 auto 95px;
  ${Media.phone`
  margin: 0 auto 72px;
  `}
  & .section-cube {
    position: absolute;
    width: ${(props) => props.cubeWidth};
    height: ${(props) => props.cubeWidth};
    border: ${(props) => props.cubeColor} solid ${(props) => props.borderWidth};
    ${Media.phone`
      display:none;
  `}
  }
  & .section-cube-leftTop {
    left: 0;
    top: 0;
    border-bottom: none;
    border-right: none;
  }
  & .section-cube-leftBottom {
    left: 0;
    bottom: 0;
    border-top: none;
    border-right: none;
  }
  & .section-cube-rightTop {
    right: 0;
    top: 0;
    border-bottom: none;
    border-left: none;
  }
  & .section-cube-rightBottom {
    right: 0;
    bottom: 0;
    border-top: none;
    border-left: none;
  }
`

const SectionFrameWrapper = ({
  children,
  cubeWidth = '61px',
  borderWidth = '4px',
  cubeColor = color.primaryDark,
}) => {
  return (
    <SectionFrame cubeWidth={cubeWidth} borderWidth={borderWidth} cubeColor={cubeColor}>
      <div className='section-cube section-cube-leftTop' />
      <div className='section-cube section-cube-leftBottom' />
      <div className='section-cube section-cube-rightTop' />
      <div className='section-cube section-cube-rightBottom' />
      {children}
    </SectionFrame>
  )
}

SectionFrameWrapper.propTypes = {
  children: PropTypes.node,
  cubeWidth: PropTypes.string,
  borderWidth: PropTypes.string,
  cubeColor: PropTypes.string,
}

export default SectionFrameWrapper
