import React from 'react'
import styled, { css } from 'styled-components'
import { color, spacing, transition } from '../../style/theme'
import { CloseIcon } from '../icons'

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  ${(p) =>
    p.center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`
const CloseIconContainer = styled.div`
  position: absolute;
  top: ${spacing.large};
  right: ${spacing.large};
  svg {
    fill: white;
    width: ${spacing.large};
    transition: ${transition('fill')};
    cursor: pointer;
    :hover {
      fill: ${color.primary};
    }
  }
`

const Modal = React.memo(function ({
  show = false,
  center = true,
  children,
  showCloseIcon = true,
  onClose = () => {},
  ...props
}) {
  return (
    show && (
      <ModalContainer {...props} center={center}>
        {children}
        {showCloseIcon && (
          <CloseIconContainer>
            <CloseIcon onClick={onClose} />
          </CloseIconContainer>
        )}
      </ModalContainer>
    )
  )
})

export default Modal
