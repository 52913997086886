import React from 'react'
import QRCodeImg from '../../images/recruitment/wechat.png'
import styled from 'styled-components'
import { fontSize, Media } from '../../style/theme'

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 43px 40px 29px;
  ${Media.phone`
  padding: 0 0 20px 0;
  `}
`
const FooterWrapperLeft = styled.div`
  display: flex;
  align-items: center;
`

const QRCodeImgWrapper = styled.div`
  display: inline-block;
  margin-right: 40px;
  ${Media.phone`
  margin-right: 18px;
  img{
    width:56px;
  }
  `}
`
const MoreInfoWrapper = styled.div`
  color: #fff;
  font-weight: 400;
  display: inline-block;
  p {
    font-size: ${fontSize.medium};
  }
  .title {
    margin-bottom: 20px;
  }
  ${Media.phone`
    p {
      font-size:9px; 
    }
    .title{
    margin-bottom: 10px;
  }`}
`
export const SubmitBtn = styled.a`
  width: 200px;
  height: 60px;
  background: #2bbfa5;
  border-radius: 30px;
  text-align: center;
  font-size: ${fontSize.large};
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  margin-top: 35px;
  ${Media.phone`
    display:none;
  `}
`
const HrEmail = styled.a`
  font-size: ${fontSize.large};
  font-weight: bold;
  color: #ffffff;
  ${Media.phone`
  font-size: ${fontSize.mini};
  `}
`
const MoreInfo = () => {
  return (
    <FooterWrapper>
      <FooterWrapperLeft>
        <QRCodeImgWrapper>
          <img src={QRCodeImg} alt='qr-code' />
        </QRCodeImgWrapper>
        <MoreInfoWrapper>
          <p className='title'>关注招聘微信号接收更多招聘信息</p>
          <p>
            HR邮箱:&nbsp;
            <HrEmail href='mailto: hr@rayvision.com' title='hr@rayvision.com'>
              hr@rayvision.com
            </HrEmail>
          </p>
        </MoreInfoWrapper>
      </FooterWrapperLeft>
      <SubmitBtn href='https://app.mokahr.com/campus-recruitment/rayvision/44818' target={'_blank'}>
        投递简历
      </SubmitBtn>
    </FooterWrapper>
  )
}

export default MoreInfo
