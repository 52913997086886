import { shape } from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { color, fontSize, Media, spacing } from '../theme'

export const PageStyle = createGlobalStyle`
  body {
    overflow: auto;
    height: 100vh;
    background: rgba(12, 22, 30, 1);
  }
`
export const BackgroundImg = styled.img`
  position: absolute;
  z-index: -1;
  object-fit: cover;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  &.bg-img-mobile {
    display: none;
  }
  ${Media.phone`
  &.bg-img-web{
    display: none;
  }
  &.bg-img-mobile{
    display: block;
  }
  `}
`
export const Title = styled.h1`
  font-size: ${fontSize.giant};
  font-family: Source Han Sans CN, Source Han Sans SC, Lato;
  color: #f3f6f7;
  text-align: center;
  padding-top: 215px;
  span {
    margin: 0 15px;
  }
  ${Media.phone`
    font-size: 30px;
    line-height: 30px;
    padding-top: 115px;
  `}
`

export const TitleImageContainer = styled.div`
  text-align: center;
  img {
    margin: 0 auto;
    display: block;
  }

  .title-image-web {
    margin-top: 18px;
  }
  .title-image-mobile {
    display: none;
  }
  ${Media.phone`
  .join-us{
    width: 70% ;
  }
   .title-image-web{
    display: none;
  }
   .title-image-mobile{
    display: block;
    margin-top:26px;
    width: 75%;
  }
  `}
`

export const TitleBtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 105px auto 77px;
  ${Media.phone`
      margin:50px auto 95px;
    `}
`
export const TitleButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  background: ${color.primaryDark};
  border-radius: 30px;
  font-size: ${fontSize.medium};
  font-weight: bold;
  &:hover {
    box-shadow: 0px 10px 24px 6px rgba(2, 166, 188, 0.4);
  }
  &.submit-resume-btn {
    background: ${color.primaryLight};
    margin-left: 80px;
  }
  &.submit-resume-btn:hover {
    box-shadow: 0px 10px 24px 6px rgba(43, 191, 165, 0.4);
  }
  & .play-image {
    margin-right: 13px;
  }
  ${Media.phone`
  width: 120px;
  height:30px;
  font-size:${fontSize.mini};
  &.submit-resume-btn {
   margin-left:20px;
  }
  & .play-image{
    margin-right: 16px;
    width: 20px;
  }
  `}
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-top: 150px;
  ${Media.phone`
  margin-top: 60px;
  `}
`

export const ArrowImgBox = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: -32px;
  ${Media.phone`
      display:none;
  `}
`
export const AttentionContent = styled.div`
  max-width: 1282px;
  border: 2px solid #02a6bc;
  background: linear-gradient(90deg, rgba(2, 166, 188, 0.4) 0%, rgba(36, 52, 58, 0.4) 100%);
  display: flex;
  align-items: center;
  margin: 61px;
  padding: 43px 60px 51px;
  ${Media.phone`
    width:90vw;
    min-width:336px;
    margin: 0 20px;
    flex-wrap:wrap;
    flex-direction:column;
    padding:26px 53px;
  `}
`
export const AttentionTitle = styled.div`
  font-size: ${fontSize.plus};
  font-weight: bold;
  color: ${color.text};
  margin-right: 50px;
  ${Media.phone`
  font-size:${fontSize.medium};
    text-align:center;
    margin-right:0;
  `}
`
export const AttentionContainer = styled.div`
  display: flex;
  align-items: center;
`
export const CityLine = styled.div`
  display: inline-block;
  width: 80px;
  height: 2px;
  margin: 0 25px 0 50px;
  background: linear-gradient(90deg, rgba(2, 166, 188, 0.8) 0%, rgba(36, 52, 58, 0) 100%);
  ${Media.phone`
  width:40px;
  margin: 0 26px 0 17px;
&.center-line{
  display:none;
}
`}
`
export const CityAttention = styled.div`
  display: inline-block;
  ${Media.phone`
    width:75px;
  `}
`

export const City = styled.div`
  font-size: ${fontSize.large};
  font-weight: bold;
  color: ${color.text};
  margin-top: 32px;
  ${Media.phone`
  font-size:15px;
  margin-top:24px;
  `}
`
export const Date = styled.div`
  font-size: ${fontSize.medium};
  font-weight: bold;
  color: ${color.text};
  /* width: 120px; */
  text-align: center;
  margin-top: 15px;
  ${Media.phone`
  font-size:15px;
  margin-top:6px;
  `}
`
export const ProcessContent = styled.div`
  max-width: 1282px;
  border: 2px solid #02a6bc;
  background: linear-gradient(90deg, rgba(2, 166, 188, 0.4) 0%, rgba(36, 52, 58, 0.4) 100%);
  padding: 60px 105px;
  margin: 61px;
  ${Media.phone`
    width: 90vw;
    margin: 23px 0;
    padding:36px 26px 0;
    `}
`

export const NoticeContent = styled.div`
  max-width: 1282px;
  border: 2px solid #02a6bc;
  background: linear-gradient(90deg, rgba(2, 166, 188, 0.4) 0%, rgba(36, 52, 58, 0.4) 100%);
  padding: 60px 10%;
  margin: 61px;
  overflow: hidden;
  ${Media.phone`
    width:90vw;
    margin: 20px 0 55px;
    padding:30px 16px 15px;
  `}
`
export const SubmitBtn = styled.a`
  display: none;
  ${Media.phone`
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 30px;
  background: #2bbfa5;
  border-radius: 30px;
  text-align: center;
  font-size: ${fontSize.mini};
  font-weight: bold;
  color: ${color.text};
  line-height: 30px;
  margin-top:15px;
`}
`

export const SubTitle = styled.div`
  font-size: ${fontSize.plus};
  font-weight: bold;
  color: ${color.text};
  line-height: 60px;
  text-align: center;
  display: relative;
  & span,
  & img {
    position: absolute;
    transform: translate(-50%, -50%);
  }
  ${Media.phone`
    line-height: 30px;
  font-size: ${fontSize.medium};
    margin-bottom: ${spacing.base};
    & img{
      width:213px;
    }
  `}
`

export const ItemBox = styled.div`
  max-width: 1280px;
  padding: 30px 40px;
  background: #ffffff;
  box-shadow: 0px 10px 16px 4px rgba(51, 51, 51, 0.35);
  border-radius: ${shape.radiusBase};
  flex-flow: column;
  ${Media.phone`
    padding: 15px 20px;
    max-width: 345px;
  `}
  &.flow-box {
    margin-top: 120px;
  }
  &.know-box {
    margin: 40px 0 45px 0;
    ${Media.phone`
      margin: 30px 0 35px 0;
    `}
  }
  &.job-box {
    border-radius: 0 10px 10px 10px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    ${Media.phone`
      flex-flow: column;
    `}
  }
`

export const FlowItem = styled.div`
  display: flex;
  margin-bottom: ${spacing.large};
  position: relative;
  .arrow-image-web {
    margin: auto 30px auto 54px;
  }
  .arrow-image-mobile {
    display: none;
  }
  ${Media.phone`
    flex-wrap:wrap;
    flex-direction:column;
    .arrow-image-web {
      display:none;
  }
    .arrow-image-mobile{
      display: block;
      margin: auto 30px auto 124px;
    }
  `}
`
export const FlowItemBox = styled.div`
  display: flex;
`

export const FlowCircle = styled.div`
  min-width: 80px;
  height: 80px;
  background: linear-gradient(0deg, #02a6bc 0%, #2bbfa5 99%);
  box-shadow: 0px 10px 16px 4px rgba(2, 166, 188, 0.35);
  border-radius: 50%;
  display: flex;
  margin-right: 145px;
  z-index: 1;
  svg {
    width: ${(props) => props.iconWidth};
    height: 40px;
    margin: auto;
  }
  ${Media.phone`
    margin-right: 0px;
    min-width: 40px;
  height: 40px;
  /* margin-right:50px; */
  svg {
    transform: scale(0.5);
  }
  `}
`

export const FlowRectangle = styled.div`
  position: absolute;
  width: 220px;
  height: 50px;
  background: ${color.primaryDark};
  border-radius: 25px;
  font-size: ${fontSize.medium};
  font-weight: bold;
  color: ${color.text};
  left: 45px;
  transform: translateY(25%);
  display: flex;
  align-items: center;
  padding-left: 60px;
  ${Media.phone`
  left:21px;
  font-size:9px;
  width:125px;
  height:25px;
  padding-left:36px;
  `}
`

export const FlowItemContent = styled.div`
  font-size: ${fontSize.base};
  font-weight: 400;
  color: #fff;
  line-height: 30px;
  margin: auto 0;
  white-space: pre-line;
  ${Media.phone`
    font-size: ${fontSize.mini};
    margin: 15px 0 0 15px;
  `}
`
export const Link = styled.a``
export const Divider = styled.div`
  max-width: 1049px;
  height: 1px;
  border: 1px solid rgba(2, 166, 188, 0.4);
  margin-bottom: 15px;
  ${Media.phone`
    margin: 9px 0;
    width:calc(90vw - 30px);
  `}
`
export const Question = styled.p`
  font-size: ${fontSize.medium};
  font-weight: 400;
  color: ${color.text};
  line-height: 30px;
  margin-bottom: 15px;
  ${Media.phone`
    font-size: ${fontSize.base};
    margin-bottom: 7px;
    line-height: 24px;
  `}
`
export const Answer = styled.p`
  font-size: ${fontSize.base};
  font-weight: 400;
  color: #bec3c5;
  line-height: 30px;
  white-space: pre-line;
  margin-bottom: 15px;
  ${Media.phone`
  font-size: ${fontSize.small};
  /* font-size: 11px; */
  line-height: 24px;
  `}
`

export const Video = styled.video`
  width: 800px;
  ${Media.phone`
    width:90%
  `}
`
export const VideoSource = styled.source``
